
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {custommostraralinicioingles_669be5160a9026961e47b8f3 } from '../../api/articulosqdlkk.api'

import './homecmmr0l.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Phomecmmr0l = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyres_669be5160a9026961e47b8f3_component_iyt5ri, setloftyres_669be5160a9026961e47b8f3_component_iyt5ri] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await custommostraralinicioingles_669be5160a9026961e47b8f3({
				});
				setloftyres_669be5160a9026961e47b8f3_component_iyt5ri(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id668c5677914da0d427fe9ced" >

      <div id="i3cg" className="">
      
      <div id="iw4tpx" className="">
      
        <a
          target=""
          className=""
          id="i7xb8r"
          href="/"
        >
          
      <img
        className=""
        id="iw2km3"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ifnuqh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwi1t2ck.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i188q6" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/services"
        type=""
      >
        <span>Services</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/events"
        type=""
      >
        <span>Events</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/contact"
        type=""
      >
        <span>Contact Us</span>
      </a>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Spanish</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i282zh" className="">
      
        <a
          target=""
          className="link-nabvar "
          id="igkctr"
          href={`/`}
        >
          
    <div id="im43t5" className="">
    <span>HOME</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iajvrv"
          href={`/en/services`}
        >
          
    <div id="i4h4c3" className="">
    <span>SERVICES</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iibluu"
          href={`/en/events`}
        >
          
    <div id="io4byk" className="">
    <span>EVENTS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iugyy5"
          href={`/en/blog`}
        >
          
    <div id="id5l3e" className="">
    <span>BLOG</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iucead" className="">
      
        <a
          target=""
          className="boton-lineal-azul "
          id="inoz1l"
          href={`/en/contact`}
        >
          
    <div id="ii95xa" className="">
    <span>CONTACT US</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="imdyke"
          href="/en"
        >
          
      <img
        className=""
        id="idnkr7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaojs7b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io94k5"
          href={`/`}
        >
          
      <img
        className=""
        id="ixrczl"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaokuhj.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="iz999t" className="">
      
      <div
        id="i3pnpq"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="i70b3l" className="">
      
      <p className="title-imacem " id="ics73h">
        <span>IMACEM S. A.</span>
      <br className="" id="" />
      
      </p>
      
      <p className="title-imacem subtitle-imacem " id="iu51kr">
        <span>Image, Communication, Enterprise, and Brand</span>
      </p>
      
      <p className="" id="i2pigk">
        
      <i className="" id="ij3c8d">
        <span>Transforming challenges into opportunities for success</span>
      </i>
      
      </p>
      
      <div id="i92aaq" className="">
      
        <a
          target=""
          className="boton-lineal-naranja "
          id="i40vca"
          href={`/servicios`}
        >
          
    <div id="ickndi" className="">
    <span>Read more</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i2ow" className="">
      
      <div id="ifuv8" className="">
      
      <div id="i9fgka" className="titulo-seccion ">
      
      <p className="titulo-seccion-imacem " id="ib4o8l">
        <span>SERVICES</span>
      </p>
      
      <p className="subtitulo-seccion-imacem " id="in8574">
        <span>Stand out with our services</span>
      </p>
      
      </div>
      
      <div id="ikaxo" className="">
      
      <div
        id="ilo28e"
        className=""
        data-aos="fade-down"
        data-aos-duration="10000"
      >
      
      <div id="i4o27" className="tajerta ">
      
      <div id="idd4g" className="">
      
      <div id="iu1pt" className="">
      
      <img
        className="img-servicio "
        id="iw65h"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3h2dl.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="ivwi3">
        <span>Communication and Image Consulting</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="igv37w"
        className=""
        data-aos="fade-down"
        data-aos-duration="10000"
      >
      
      <div id="ijp4bj" className="tajerta ">
      
      <div id="ix6um3" className="">
      
      <div id="i1zej" className="">
      
      <img
        className="img-servicio "
        id="isluk"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3injl.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="iv5rgk">
        <span>Crisis Management</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="i7xa9p"
        className=""
        data-aos="fade-down"
        data-aos-duration="10000"
      >
      
      <div id="i3zg7" className="tajerta ">
      
      <div id="ijd9q" className="">
      
      <div id="ie9oo" className="">
      
      <img
        className="img-servicio "
        id="iwrff"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3jhp8.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="ije0t">
        <span>Specialized Training</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="ig2s32"
        className=""
        data-aos="fade-down"
        data-aos-duration="10000"
      >
      
      <div id="i6xebl" className="tajerta ">
      
      <div id="i1n0f9" className="">
      
      <div id="iikhi2" className="">
      
      <img
        className="img-servicio "
        id="i43u4t"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3l514.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="id21fx">
        <span>Brand Ambassador and Moderation</span>
      </p>
      
      </div>
      
      </div>
      
      <div
        id="ihzpk7"
        className=""
        data-aos="fade-down"
        data-aos-duration="10000"
      >
      
      <div id="i9rgcm" className="tajerta ">
      
      <div id="ika8s7" className="">
      
      <div id="idnsai" className="">
      
      <img
        className="img-servicio "
        id="ih1kn7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3mv6m.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <p className="" id="iuo0iw">
        <span>Personal and Corporate Brand Development</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i9n2" className="">
      
      <div id="i65amn" className="titulo-seccion ">
      
      <p className="titulo-seccion-imacem " id="ihgxib">
        <span>ABOUT US</span>
      </p>
      
      </div>
      
      <div id="ipuxfl" className="">
      
      <div id="izeymg" className="">
      
      <div
        id="iyhhw3"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <img
        className=""
        id="ijylrh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3q477.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div
        id="iu127m"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <img
        className=""
        id="ip5g8s"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw3tpr6.jpg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="imwp6h" className="">
      
      <div id="idwzm3" className="">
      
      <p className="" id="i2l4sd">
        <span>Our Philosophy</span>
      </p>
      
      <p className="" id="ic97nk">
        <span>We believe in the importance of assertive and authentic communication and effective management that emotionally connect with your audience. Our approach focuses on creating organic strategies to solve communication and image challenges, fostering genuine loyalty with your audience.</span>
      <br className="" id="" />
      
      </p>
      
      <div id="irxvll" className="">
      
        <a
          target=""
          className="boton-lineal-azul large-button "
          id="izpc7n"
          href={`/servicios`}
        >
          
      <p className="" id="it0inh">
        <span>Read more</span>
      </p>
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izg5" className="">
      
      <div id="inibo6" className="titulo-seccion ">
      
      <p className="titulo-seccion-imacem " id="itghdq">
        <span>WHY CHOOSE US?</span>
      </p>
      
      <p className="subtitulo-seccion-imacem " id="ib6e0r">
        <span>Transforming challenges into opportunities for success</span>
      </p>
      
      </div>
      
      <div id="ijcy9b" className="">
      
      <div id="i0jvoi" className="">
      
      <div id="ipfeog" className="tarjeta ">
      
      <div id="it0t47" className="">
      
      <img
        className=""
        id="i1lbie"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw4l961.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="ixq5ql" className="">
      
      <p className="titulo-tarjeta " id="iz2b8p">
        <span>Proven Experience</span>
      </p>
      
      <p className="descripcion-tarjeta " id="iyw3al">
        <span>Trabajamos con expertos con años de experiencia en comunicación, imagen y marketing digital</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i3ho6u" className="tarjeta ">
      
      <div id="iuv1r7" className="">
      
      <img
        className=""
        id="i4q4uj"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw4jny2.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="ihnf3g" className="">
      
      <p className="titulo-tarjeta " id="idd801">
        <span>Customized Approach</span>
      </p>
      
      <p className="descripcion-tarjeta " id="imr5qh">
        <span>Soluciones a medida que reflejan su identidad y cumplan con sus objetivos</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="itgpjo" className="tarjeta ">
      
      <div id="i3a3eo" className="">
      
      <img
        className=""
        id="igpqot"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxw4m8fe.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="izejpj" className="">
      
      <p className="titulo-tarjeta " id="i040xk">
        <span>Results-Oriented</span>
      </p>
      
      <p className="descripcion-tarjeta " id="ikq2x5">
        <span>Nos enfocamos en obtener resultados tangibles que impulsen su éxito</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ipvc3" className="">
      
      <div id="imdp4j" className="titulo-seccion ">
      
      <p className="titulo-seccion-imacem " id="iffnpy">
        <span>BLOG</span>
      </p>
      
      <p className="subtitulo-seccion-imacem " id="i8bur9">
        <span>Recent Articles</span>
      </p>
      
      </div>
      
      <div id="ieuh5w" className="">
      
        <div id="iyt5ri" className="">
        
          {
            loftyres_669be5160a9026961e47b8f3_component_iyt5ri.map((loftyitem_669be5160a9026961e47b8f3_component_iyt5ri) => (
              <div key={loftyitem_669be5160a9026961e47b8f3_component_iyt5ri._id} className="" id="idy30f">
                
      <div
        id="if2zj9"
        className="tarjeta-blog "
        data-aos="fade-left"
        data-aos-duration="10000"
      >
      
      <div id="inqqhh" className="">
      
      <div id="ih6wwk" className="">
      
        <img
          className=""
          id="icmos7"
          alt=""
          src={loftyitem_669be5160a9026961e47b8f3_component_iyt5ri.loftyUrl}
        />
        
      </div>
      
        <a
          target=""
          className=""
          id="ir8s5e"
          href={`/article/${loftyitem_669be5160a9026961e47b8f3_component_iyt5ri._id}`}
        >
          
      <div id="iq46jc" className="">
      
        <div className="" id="igrdy7">
          
          <div className="" id="ievr6v">
            {loftyitem_669be5160a9026961e47b8f3_component_iyt5ri?.titulostjv1}
          </div>
          
        </div>
        
      </div>
      
        </a>
        
      </div>
      
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      <div id="i9zfqe" className="">
      
        <a
          target=""
          className="boton-lineal-naranja "
          id="iwoswk"
          href={`/en/blog`}
        >
          
    <div id="il33xc" className="">
    <span>Read more</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <div id="iat5x" className="">
      
      <div id="ighxck" className="">
      
      <img
        className=""
        id="inqivl"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipa2qp" className="">
      
      <div id="ise2r9" className="">
      
      <div id="i0qriz" className="">
      
      <p className="" id="i14ubl">
        <span>Office</span>
      </p>
      
      <div id="i8hlod" className="">
      
      <p className="" id="icpwci">
        <span>Honduras - Tegucigalpa MDC</span>
      </p>
      
      <p className="" id="i0vnkg">
        
      <a
        target=""
        className=""
        id="isvk7j"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
      </div>
      
      <p className="" id="i7jvdl">
        <span>+504 9982-3483</span>
      </p>
      
      </div>
      
      <div id="ia2by2" className="">
      
      <p className="" id="i5kwyk">
        <span>Links</span>
      </p>
      
      <div id="ijr81i" className="">
      
      <p className="" id="izt1ml">
        <span>Home</span>
      </p>
      
      <p className="" id="ip0pg2">
        <span>Events</span>
      </p>
      
      <p className="" id="i4aa3m">
        
      <a
        target=""
        className="vinculo-negro "
        id="ij029j"
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </p>
      
      <p className="" id="iiv8pp">
        <span>Contact Us</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ieb919" className="">
      
      <p className="" id="ic9ies">
        <span>Social Media</span>
      </p>
      
      <div id="idmvel" className="">
      
      <p className="" id="icjl6l">
        
      <a
        target="_blank"
        className=""
        id="i6vqxi"
        href="https://www.linkedin.com/company/imacem-consultores-s-a/"
        type=""
      >
        <span>Linkedin</span>
      </a>
      
      </p>
      
      <p className="" id="io7ssq">
        
      <a
        target=""
        className=""
        id="ih7p4z"
        href="https://www.instagram.com/imacemconsultores/"
        type=""
      >
        <span>Instagram</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixz1b7" className="">
      
      <p className="" id="i4plpg">
        <span>IMACEM © 2024. All rights reserved.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Phomecmmr0l);
  