
import axios from '../config/axios';

export const url = '/api/eventosg1lgej'


export const customeventospublicos_667de35da2fa806a8b0ccc42 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/publicos`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const customlistadodeeventosenespañol_66855279a2fa806a8b0ce1e4 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/eventos/spanish`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const customobtenereningles_66912c5bc267708aecc013cd = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/obtenereningles`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});



