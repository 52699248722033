
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyUser, deleteLoftytoken} from './actions/auth.actions';

// pages
import paginainicial from './pages/paginainicial/paginainicial'
import paginamuypronto from './pages/paginamuypronto/paginamuypronto'
import servicios2 from './pages/servicios2/servicios2'
import contactoimacem from './pages/contactoimacem/contactoimacem'
import eventos from './pages/eventos/eventos'
import paginablog from './pages/paginablog/paginablog'
import homecmmr0l from './pages/homecmmr0l/homecmmr0l'
import contactxnb8h from './pages/contactxnb8h/contactxnb8h'
import services7phyn from './pages/services7phyn/services7phyn'
import eventsg94hr from './pages/eventsg94hr/eventsg94hr'
import blogpagefmolt from './pages/blogpagefmolt/blogpagefmolt'
import visualizaciondearticulorcjdq from './pages/visualizaciondearticulorcjdq/visualizaciondearticulorcjdq'
import articulexu9t2 from './pages/articulexu9t2/articulexu9t2'


// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = ({loftytoken, addLoftyUserApp, deleteLoftytokenApp}) => {

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  

  return (
    <Router>
      <Switch>
			<Route exact path="/" component={paginainicial} />
			<Route exact path="/muypronto" component={paginamuypronto} />
			<Route exact path="/servicios" component={servicios2} />
			<Route exact path="/contacto" component={contactoimacem} />
			<Route exact path="/eventos" component={eventos} />
			<Route exact path="/blog" component={paginablog} />
			<Route exact path="/en" component={homecmmr0l} />
			<Route exact path="/en/contact" component={contactxnb8h} />
			<Route exact path="/en/services" component={services7phyn} />
			<Route exact path="/en/events" component={eventsg94hr} />
			<Route exact path="/en/blog" component={blogpagefmolt} />
			<Route exact path="/articulo/:id" component={visualizaciondearticulorcjdq} />
			<Route exact path="/article/:id" component={articulexu9t2} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyUserApp: (user) => dispatch(addLoftyUser(user)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

