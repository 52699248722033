
import axios from '../config/axios';

export const url = '/api/articulosqdlkk'


export const customlistadodearticulos_668534c2a2fa806a8b0cdea5 = ({
  busqueda,

}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/listadodearticulos`, {
      params: {
        busqueda,

      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const customarticulosespañol_66902933c267708aecc01125 = ({
  busqueda,

}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/articulos/espanol`, {
      params: {
        busqueda,

      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const customobtenereningles_66912d46c267708aecc0154a = ({
  busqueda,

}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/obtenereningles`, {
      params: {
        busqueda,

      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const getOnearticulosqdlkk = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/669509fb0a9026961e47b03c/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      
export const custommostraralinicio_669be47f0a9026961e47b897 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/mostrar/inicio/espanol`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const custommostraralinicioingles_669be5160a9026961e47b8f3 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/mostrar/inicio/ingles`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});



