
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'

import './contactoimacem.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pcontactoimacem = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id66524b46914da0d427fd60b0" >

      <div id="i3cg" className="">
      
      <div id="iw4tpx" className="">
      
        <a
          target=""
          className=""
          id="i7xb8r"
          href="/"
        >
          
      <img
        className=""
        id="iw2km3"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ifnuqh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwi1t2ck.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i188q6" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/servicios"
        type=""
      >
        <span>Servicios</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/eventos"
        type=""
      >
        <span>Eventos</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/contact"
        type=""
      >
        <span>Inglés</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i282zh" className="">
      
        <a
          target=""
          className="link-nabvar "
          id="igkctr"
          href={`/`}
        >
          
    <div id="im43t5" className="">
    <span>INICIO</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iajvrv"
          href={`/servicios`}
        >
          
    <div id="i4h4c3" className="">
    <span>SERVICIOS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iibluu"
          href={`/eventos`}
        >
          
    <div id="io4byk" className="">
    <span>EVENTOS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iugyy5"
          href={`/blog`}
        >
          
    <div id="id5l3e" className="">
    <span>BLOG</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iucead" className="">
      
        <a
          target=""
          className="boton-lineal-azul "
          id="inoz1l"
          href={`/contacto`}
        >
          
    <div id="ii95xa" className="">
    <span>CONTACTAR</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="imdyke"
          href="/en/contact"
        >
          
      <img
        className=""
        id="idnkr7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaojs7b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io94k5"
          href="#"
        >
          
      <img
        className=""
        id="ixrczl"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaokuhj.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="iz999t" className="">
      
      <div id="iwj18p" className="">
      
      <div
        id="izpzwf"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <span className="" id="i8qky5">
        <span>CONTACTO</span>
      </span>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ipvc3" className="">
      
      <div
        id="io444i"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="ialofi" className="">
      
      <img
        className=""
        id="i2pjh8"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwmk3g5b.png"
        alt="undefined"
      />
      
      <div id="ij0bfi" className="">
      
        <div className="" id="iiizvw">
          
      <form
        id="i5j82w"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontacto({
						name: e.target.name.value,
						telephone: e.target.telephone.value,
						message: e.target.message.value,
						email: e.target.email.value,
						subject: e.target.subject.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="in2bd7" className="">
      
      <br className="" id="" />
      
      <input
        name="name"
        type="text"
        placeholder="Nombre"
        className="input "
        id="ihydjw"
      />
      
      </div>
      
      <div id="i0xsj1" className="">
      
      <br className="" id="" />
      
      <input
        name="telephone"
        type="text"
        placeholder="Telefono"
        className="input "
        id="idqtvq"
      />
      
      </div>
      
      <div id="ivh6af" className="">
      
      <br className="" id="" />
      
      <input
        name="message"
        type="text"
        placeholder="Mensaje"
        className="input "
        id="igbnxe"
      />
      
      </div>
      
      <div id="ifdhkx" className="">
      
      <br className="" id="" />
      
      <input
        name="email"
        type="text"
        placeholder="Correo"
        className="input "
        id="i4fbga"
      />
      
      </div>
      
      <div id="ixuyq4" className="">
      
      <br className="" id="" />
      
      <input
        name="subject"
        type="text"
        placeholder="Tema"
        className="input "
        id="ib1ypi"
      />
      
      </div>
      
      <div id="iul7yi" className="">
      
      <button
        type="submit"
        name=""
        id="i51tn9"
        className="boton-lineal-naranja "
        
      >
      
      <div id="i760gi" className="">
      <span>ENVIAR</span>
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="iat5x" className="">
      
      <div id="ighxck" className="">
      
      <img
        className=""
        id="inqivl"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipa2qp" className="">
      
      <div id="ise2r9" className="">
      
      <div id="i0qriz" className="">
      
      <p className="" id="i14ubl">
        <span>Oficina</span>
      </p>
      
      <div id="i8hlod" className="">
      
      <p className="" id="icpwci">
        <span>Honduras - Tegucigalpa MDC</span>
      </p>
      
      <p className="" id="i0vnkg">
        
      <a
        target=""
        className=""
        id="iigjyw"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
      </div>
      
      <p className="" id="i7jvdl">
        <span>+504 9982-3483</span>
      </p>
      
      </div>
      
      <div id="ia2by2" className="">
      
      <p className="" id="i5kwyk">
        <span>Links</span>
      </p>
      
      <div id="ijr81i" className="">
      
      <p className="" id="izt1ml">
        
      <a
        target=""
        className="vinculo-negro "
        id="i0dsjx"
        href="/"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </p>
      
      <p className="" id="ip0pg2">
        
      <a
        target=""
        className="vinculo-negro "
        id="i0qg4t"
        href="/eventos"
        type=""
      >
        <span>Eventos</span>
      </a>
      
      </p>
      
      <p className="" id="i4aa3m">
        
      <a
        target=""
        className="vinculo-negro "
        id="ie752i"
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </p>
      
      <p className="" id="iiv8pp">
        
      <a
        target=""
        className="vinculo-negro "
        id="ixb9qh"
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      <div id="ieb919" className="">
      
      <p className="" id="ic9ies">
        <span>Sociales</span>
      </p>
      
      <div id="idmvel" className="">
      
      <p className="" id="icjl6l">
        
      <a
        target="_blank"
        className=""
        id="ikf48h"
        href="https://www.linkedin.com/company/imacem-consultores-s-a/"
        type=""
      >
        <span>Linkedin</span>
      </a>
      
      </p>
      
      <p className="" id="io7ssq">
        
      <a
        target="_blank"
        className=""
        id="iv8rtt"
        href="https://www.instagram.com/imacemconsultores/"
        type=""
      >
        <span>Instagram</span>
      </a>
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixz1b7" className="">
      
      <p className="" id="i4plpg">
        <span>IMACEM © 2024. Todos los derechos reservados.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pcontactoimacem);
  