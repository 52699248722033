
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {customobtenereningles_66912d46c267708aecc0154a } from '../../api/articulosqdlkk.api'

import './blogpagefmolt.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Pblogpagefmolt = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {
	const [loftystate_textodebusqueda, set_loftystate_textodebusqueda] = useState()
	const [loftystate_busqueda, set_loftystate_busqueda] = useState()

	const [loftyres_66912d46c267708aecc0154a_component_ibidnu, setloftyres_66912d46c267708aecc0154a_component_ibidnu] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await customobtenereningles_66912d46c267708aecc0154a({
					busqueda: loftystate_busqueda,
				});
				setloftyres_66912d46c267708aecc0154a_component_ibidnu(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, [loftystate_busqueda, ]);

	function function_realizarbusqueda9ymt8_66950749914da0d427fede00() {
		set_loftystate_busqueda(loftystate_textodebusqueda);
	}


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id668c57d4914da0d427fe9da5" >

      <div id="i3cg" className="">
      
      <div id="iw4tpx" className="">
      
        <a
          target=""
          className=""
          id="i7xb8r"
          href="/"
        >
          
      <img
        className=""
        id="iw2km3"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      <img
        className=""
        id="ifnuqh"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwi1t2ck.png"
        alt="undefined"
      />
      
        </a>
        
      <div id="i188q6" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/services"
        type=""
      >
        <span>Services</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/events"
        type=""
      >
        <span>Events</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/en/contact"
        type=""
      >
        <span>Contact Us</span>
      </a>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/blog"
        type=""
      >
        <span>Spanish</span>
      </a>
      
      </li>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i282zh" className="">
      
        <a
          target=""
          className="link-nabvar "
          id="igkctr"
          href={`/en`}
        >
          
    <div id="im43t5" className="">
    <span>HOME</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iajvrv"
          href={`/en/services`}
        >
          
    <div id="i4h4c3" className="">
    <span>SERVICES</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iibluu"
          href={`/en/events`}
        >
          
    <div id="io4byk" className="">
    <span>EVENTS</span>
    </div>
    
        </a>
        
        <a
          target=""
          className="link-nabvar "
          id="iugyy5"
          href={`/en/blog`}
        >
          
    <div id="id5l3e" className="">
    <span>BLOG</span>
    </div>
    
        </a>
        
      </div>
      
      <div id="iucead" className="">
      
        <a
          target=""
          className="boton-lineal-azul "
          id="inoz1l"
          href={`/en/contact`}
        >
          
    <div id="ii95xa" className="">
    <span>CONTACT US</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="imdyke"
          href="/en/blog"
        >
          
      <img
        className=""
        id="idnkr7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaojs7b.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="io94k5"
          href={`/blog`}
        >
          
      <img
        className=""
        id="ixrczl"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilwaokuhj.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="iz999t" className="">
      
      <div id="iwj18p" className="">
      
      <div
        id="izpzwf"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <span className="" id="i8qky5">
        <span>BLOG</span>
      </span>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="izg5" className="">
      
      <div id="ijcy9b" className="">
      
      <div id="iif17w" className="">
      
      <div id="i0jv0l" className="tarjeta tarjeta-busqueda ">
      
      <div id="ihqfm2" className="">
      
        <div id="undefined" className="inputContainer ">
          
        <input
          name=""
          type="text"
          placeholder="Enter a title"
          className=""
          id="isfaby"
          onChange={(e) => {
            set_loftystate_textodebusqueda(e.target.value);
          }}
          value={loftystate_textodebusqueda}
        />
        
        </div>
      
      <button
        type=""
        name=""
        id="itdstb"
        className="boton-lineal-azul margenesbutton "
        onClick={async () => {
		function_realizarbusqueda9ymt8_66950749914da0d427fede00();
}}

      >
      
      <div id="ihg9wf" className="">
      <span>Search</span>
      </div>
      
      </button>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
        <div id="ibidnu" className="">
        
          {
            loftyres_66912d46c267708aecc0154a_component_ibidnu.map((loftyitem_66912d46c267708aecc0154a_component_ibidnu) => (
              <div key={loftyitem_66912d46c267708aecc0154a_component_ibidnu._id} className="" id="ilc3k4">
                
      <div id="iiei6l" className="tarjeta-blog ">
      
      <div id="ise1o1" className="">
      
        <img
          className=""
          id="i89tvv"
          alt=""
          src={loftyitem_66912d46c267708aecc0154a_component_ibidnu.loftyUrl}
        />
        
      </div>
      
        <a
          target=""
          className=""
          id="i9tfpi"
          href={`/article/${loftyitem_66912d46c267708aecc0154a_component_ibidnu._id}`}
        >
          
        <div className="titulo-tarjeta " id="ibc7eh">
          
          <div className="" id="ih6czq">
            {loftyitem_66912d46c267708aecc0154a_component_ibidnu?.titulostjv1}
          </div>
          
        </div>
        
        </a>
        
      <div id="ihic5j" className="descripcion-tarjeta ">
      
        <div className="" id="i6t1l6">
          
          <div className="" id="i311gl">
            {loftyitem_66912d46c267708aecc0154a_component_ibidnu?.descripcioncortabpxfi}
          </div>
          
        </div>
        
      </div>
      
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      </div>
      
      <div id="iat5x" className="">
      
      <div id="ighxck" className="">
      
      <img
        className=""
        id="inqivl"
        src="https://assetsprojects.s3.amazonaws.com/406racilu44h9vu.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="ipa2qp" className="">
      
      <div id="ise2r9" className="">
      
      <div id="i0qriz" className="">
      
      <p className="" id="i14ubl">
        <span>Office</span>
      </p>
      
      <div id="i8hlod" className="">
      
      <p className="" id="icpwci">
        <span>Honduras - Tegucigalpa MDC</span>
      </p>
      
      <p className="" id="i0vnkg">
        <span>contact@imacempro.com</span>
      </p>
      
      </div>
      
      <p className="" id="i7jvdl">
        <span>+504 9982-3483</span>
      </p>
      
      </div>
      
      <div id="ia2by2" className="">
      
      <p className="" id="i5kwyk">
        <span>Links</span>
      </p>
      
      <div id="ijr81i" className="">
      
      <p className="" id="izt1ml">
        <span>Home</span>
      </p>
      
      <p className="" id="ip0pg2">
        <span>Events</span>
      </p>
      
      <p className="" id="i4aa3m">
        
      <a
        target=""
        className="vinculo-negro "
        id="ie752i"
        href="/blog"
        type=""
      >
        <span>Blog</span>
      </a>
      
      </p>
      
      <p className="" id="iiv8pp">
        <span>Contact Us</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ieb919" className="">
      
      <p className="" id="ic9ies">
        <span>Social Media</span>
      </p>
      
      <div id="idmvel" className="">
      
      <p className="" id="icjl6l">
        <span>Linkedin</span>
      </p>
      
      <p className="" id="io7ssq">
        <span>Instagram</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ixz1b7" className="">
      
      <p className="" id="i4plpg">
        <span>IMACEM © 2024. All rights reserved.</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Pblogpagefmolt);
  